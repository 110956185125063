
import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
interface Experience {
    user_id: number | null;
    title: string;
    company: string;
    from_period: Date | null;
    to_period: Date | null;
    currently_work_here: boolean;
    description: string;
}

export default defineComponent({
    name: 'CreateExperienceView',
    setup() {
        const store = useStore();
        const experience = ref<Experience>({
            user_id: store.state.user_id,
            title: '',
            company: '',
            from_period: null,
            to_period: null,
            currently_work_here: false,
            description: ''
        });

        const addExperience = async () => {
            if (!experience.value.title || !experience.value.company || !experience.value.from_period) {
                alert('Please fill in all the required fields.');
                return;
            }

            try {
                const apiUrl = store.state.host_url + "user-experience";
                const response = await axios.post(apiUrl, experience.value);

                if (response.data.message === 'Work experience added successfully.') {
                    alert(response.data.message);
                    Object.assign(experience.value, {
                        user_id: store.state.user_id,
                        title: '',
                        company: '',
                        from_period: null,
                        to_period: null,
                        currently_work_here: false,
                        description: ''
                    });
                } else {
                    alert('Error adding work experience: ' + (response.data.message || 'Unknown error'));
                }
            } catch (error) {
                alert('Error adding work experience.');
            }
        };

        const toggleCurrentlyWorkHere = () => {
            experience.value.currently_work_here = !experience.value.currently_work_here;
            if (experience.value.currently_work_here) {
                experience.value.to_period = null;
            }
        }

        return {
            experience,
            addExperience,
            toggleCurrentlyWorkHere
        };
    }
});
