
import { defineComponent } from 'vue';
import ImageHero from '@/components/HeroImage/HeroImage.components.vue'
import UploadProject from '@/components/UploadProject/UploadProject.components.vue'
import LatestProject from '@/components/LatestProject/LatestProject.components.vue'
import AboutUs from '@/components/AboutUs/AboutUs.components.vue'

export default defineComponent({
  name: 'HomeView',
  components: {
    ImageHero,
    UploadProject,
    LatestProject,
    AboutUs,
  },
});
