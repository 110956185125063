
import { defineComponent, ref, onMounted,computed  } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
name: 'EditExperienceView',
setup() {
    const route = useRoute();
    const experienceId = route.params.id; // Capture the ID from the URL
    const store = useStore();
    const experience = ref({
        title: '', 
        company: '',
        from_period: '',
        to_period: '',
        description: '',
        currently_work_here: '1'
    });
    const apiUrl = store.state.host_url + `user-experience/${experienceId}`; 
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        
        return `${year}-${month}-${day}`;
    };

    const updateExperience = async () => {
        try {
           
            const response = await axios.post(apiUrl, experience.value);

            if (response.data.success) {
            alert('Experience updated successfully');
            } else {
            alert('Error updating experience: ' + response.data.message);
            }
        } catch (error) {
            alert('Network error. Please try again.');
        }
        };

        const isCurrentChecked = () => {
        if (experience.value.currently_work_here) {
            experience.value.to_period = getCurrentDate();
        } else {
            experience.value.to_period = '';  // Or set a default value if needed
        }
    };
    
    const isCurrentlyWorkingHere = computed({
      get: () => experience.value.currently_work_here === '1',
      set: (newValue) => {
        experience.value.currently_work_here = newValue ? '1' : '0';
      }
    });
    onMounted(async () => {
        try {

            const response = await axios.get(apiUrl);
            
            if (response.data) {
                experience.value = response.data.experience;
            } else {
                console.error('Error fetching experience data:', response.data.message);
            }
        } catch (error) {
            console.error('Network error fetching experience:', error);
        }
    });

    return {
        experience,
        isCurrentChecked,
        updateExperience,
        isCurrentlyWorkingHere
    };
}
});
