
import { defineComponent, PropType } from 'vue';
import CardDetails from './types';

export default defineComponent({
  name: 'DisplayCard',
  props: {
    CardDatas: {
        required: true,
        type: Array as PropType<CardDetails[]>
    }
  },
});
