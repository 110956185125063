
import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

interface UserProfile {
  name: string;
  phone: string;
  email: string;
  gender: string;
  dob: string;
  year_experience: number;
  wage_per_hour:number;
  cover_note:string;
  skills: Skill[]; 
}

interface Skill {
  ID: number;
  skill_name: string;
  skill_proficiency: number;
}

interface Experience {
  ID: number;
  title: string;
  company: string;
  from_period: string;
  to_period: string;
  description: string;
}

interface Education {
  ID: number;
  education_level: string;
  year_of_education: number | null;
  venue_of_education: string;
  major: string;
  from_period: string;
  to_period: string;
}

export default defineComponent({
  name: 'AccountView',
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const userProfile = ref<UserProfile | null>(null);
    const updatedName = ref<string>('');
    const updatedPhone = ref<string>('');
    const userSkills = ref<Skill[]>([]);
    const isAddedSkills = ref<boolean>(true); // Temporary - Update based on your logic.
    const isAddedOthers = ref<boolean>(true); // Temporary - Update based on your logic.
    const userExperience = ref<Experience[]>([]);
    const userEducation = ref<Education[]>([]);
    const handleSaveChanges = async () => {
        try {
          const apiUrl = store.state.host_url + "user/update-profile";  // Concatenate host_url with endpoint
         
            const response = await axios.post(apiUrl, {
              name: updatedName.value,
              phone: updatedPhone.value,
              user_id: store.state.user_id
            });



            if (response.data.success) {
              alert('Profile updated successfully');
            } else {
              alert('Error updating profile: ' + response.data.message);
            }
        } catch (error) {
            alert('Network error. Please try again.');
        }
    };
    const removeExperience = async (id: number) => {
      try {
          const url = `${store.state.host_url}user-experience/${id}`;
          console.log(url)
          const response = await axios.delete(url);
          console.log(response.data)
          console.log(response.data.success)
          if (response.data.success) {
              // Refresh data or remove locally
              userExperience.value = userExperience.value.filter(exp => exp.ID !== id);
              alert(t('message.experienceRemovedSuccessfully'));
          } else {
              alert(t('message.errorRemovingExperience'));
          }
      } catch (error) {
          alert(t('message.networkError'));
      }
    };

    const removeEducation = async (id: number) => {
        try {
            const url = `${store.state.host_url}user-education/${id}`;
            const response = await axios.delete(url);

            if (response.data.success) {
                // Remove the education entry from the list
                userEducation.value = userEducation.value.filter(edu => edu.ID !== id);
                alert(t('message.EducationRemovedSuccessfully'));
            } else {
                alert(t('message.ErrorRemovingEducation'));
            }
        } catch (error) {
            console.error("Error removing education:", error);
            alert(t('message.NetworkError'));
        }
    };

    type EducationKey = 'primary_school' | 'middle_school' 
      |'high_school' |'vocational' 
      |'diploma' |'associate'
      |'bachelor' | 'postgraduate'
      |'masters' |'phd'
      |'professional';

   
    function getEducationLevel(level: string): string {
      const educationLevels: { [key: string]: string } = {
        'primary_school': t('message.primarySchool'),
        'middle_school': t('message.middleSchool'),
        'high_school': t('message.highSchool'),
        'vocational': t('message.vocational'),
        'diploma': t('message.diploma'),
        'associate': t('message.associateDegree'),
        'bachelor': t('message.bachelorsDegree'),
        'postgraduate': t('message.postgraduateDiploma'),
        'masters': t('message.mastersDegree'),
        'phd': t('message.doctorate'),
        'professional': t('message.professionalQualification')
      };
      
      return educationLevels[level] || level;
    }

    onMounted(async () => {
      try {
        const url = `${store.state.host_url}user/profile/${store.state.user_id}`;
        const response = await axios.post(url);
        userProfile.value = response.data.user;
        userSkills.value = response.data.skills; 
        userExperience.value = response.data.experience;
        userEducation.value = response.data.education;
        updatedName.value = userProfile.value?.name || '';
        updatedPhone.value = userProfile.value?.phone || '';
        console.log(userProfile.value)
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    });

    return {
      userProfile,
      userSkills,
      userExperience,
      userEducation,
      updatedName,
      updatedPhone,
      isAddedSkills,
      isAddedOthers,
      handleSaveChanges,
      getEducationLevel,
      removeExperience,
      removeEducation
    };
  },
  
});
