
import { defineComponent } from 'vue';
import HeroButton from '@/components/HeroButton/HeroButton.components.vue'

export default defineComponent({
  name: 'HeroImage',
  components: {
    HeroButton,
  },
  props: {},
});

