
import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';

interface ProjectSkill {
  ID: number;
  project_id: number;
  skill_id: number | null;
  skill_name: string;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;
}

interface Project {
  ID: number;
  user_id: number;
  title: string;
  description: string;
  status: string;
  budget: string;
  pic_name: string;
  pic_phone: string;
  pic_email: string;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;
  category_id: number;
  project_skills: ProjectSkill[];
}

export default defineComponent({
  name: 'FreelancerProject',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const project = ref<Project | null>(null);
    const store = useStore();
    onMounted(async () => {
      try {
        const response = await axios.get(`${store.state.host_url}project/${props.id}`);
        console.log(response.data)
        project.value = response.data.project;
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    });

    return {
      project
    };
  }
});

