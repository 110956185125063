

import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

export default defineComponent({
  name: 'EditProjectView',
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const BASE_URL = store.state.host_url;
    
    const allSkills = ref<Array<{ ID: number; name_eng: string }>>([]);
    const categories = ref<Array<{ ID: number; name_eng: string }>>([]);

    const skillInput = ref('');
    const filteredSkills = ref<string[]>([]);
    const isAutocompleteVisible = ref(false);
    const skillSet = ref<string[]>([]);
    
    const projectTitle = ref('');
    const projectDescription = ref('');
    const projectBudget = ref<number>(1); // default value set to 100, assuming it's a number
    const selectedCategory = ref<number | null>(null);
    const personInChargeName = ref('');
    const personInChargePhone = ref('');
    const personInChargeEmail = ref('');

    onMounted(async () => {
      try {
        const skillsResponse = await axios.get(BASE_URL + "skills");
        if (skillsResponse.data && Array.isArray(skillsResponse.data)) {
          allSkills.value = skillsResponse.data;
        }

        const categoryResponse = await axios.get(BASE_URL + "categories");
        if (categoryResponse.data && Array.isArray(categoryResponse.data)) {
          categories.value = categoryResponse.data;
        }
        const projectResponse = await axios.get(`${BASE_URL}project/${props.id}`);
        if (projectResponse.data) {
          const project = projectResponse.data.project;
          projectTitle.value = project.title;
          projectDescription.value = project.description;
          projectBudget.value = project.budget;
          selectedCategory.value = project.category_id;
          if (project.project_skills && Array.isArray(project.project_skills)) {
              skillSet.value = project.project_skills.map((skill: any) => skill.skill_name);
          }

          personInChargeName.value = project.pic_name;
          personInChargePhone.value = project.pic_phone;
          personInChargeEmail.value = project.pic_email;

        }
        console.log(projectResponse.data)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });

    const filterSkills = () => {
      if (!skillInput.value) {
        filteredSkills.value = [];
        isAutocompleteVisible.value = false;
        return;
      }

      filteredSkills.value = allSkills.value
        .map(skill => skill.name_eng)
        .filter(name => name.toLowerCase().includes(skillInput.value.toLowerCase()));

      isAutocompleteVisible.value = filteredSkills.value.length > 0;
    }

    const selectSkill = (skill: string) => {
      skillInput.value = skill;
      filteredSkills.value = [];
    }

    const addSelectedSkill = () => {
      if (skillInput.value && !skillSet.value.includes(skillInput.value)) {
        skillSet.value.push(skillInput.value);
        skillInput.value = '';
      }
    }

    const deleteSkill = (index: number) => {
      skillSet.value.splice(index, 1);
    }

    const updateProject = async () => {
      try {
        const mappedSkills = skillSet.value.map(skillName => {
          const foundSkill = allSkills.value.find(skill => skill.name_eng === skillName);
          return {
            id: foundSkill ? foundSkill.ID : null,
            name: skillName
          };
        });

        var data = {
          title: projectTitle.value,
          description: projectDescription.value,
          budget: projectBudget.value,
          category_id: selectedCategory.value,
          skills: mappedSkills,
          user_id: store.state.user_id,
          person_in_charge: {
            name: personInChargeName.value,
            phone: personInChargePhone.value,
            email: personInChargeEmail.value
          }
        }

        const response = await axios.put(`${BASE_URL}project/${props.id}`, data);
        console.log(data)
        if (response.status === 200) {
          alert("Project updated successfully");
          router.push("/my-project");
        }
        else {
          alert("An error occurred while updating the project.");
        }

      } catch (error) {
        const mappedSkills = skillSet.value.map(skillName => {
          const foundSkill = allSkills.value.find(skill => skill.name_eng === skillName);
          return {
            id: foundSkill ? foundSkill.ID : null,
            name: skillName
          };
        });

        var data = {
          title: projectTitle.value,
          description: projectDescription.value,
          budget: projectBudget.value,
          category_id: selectedCategory.value,
          skills: mappedSkills,
          user_id: store.state.user_id,
          person_in_charge: {
            name: personInChargeName.value,
            phone: personInChargePhone.value,
            email: personInChargeEmail.value
          }
        }

        console.error("Error:", error);
        console.log(data)
        alert("Failed to update the project. Please try again.");
      }
    };


    return {
      skillSet,
      skillInput,
      filteredSkills,
      filterSkills,
      selectSkill,
      addSelectedSkill,
      deleteSkill,
      isAutocompleteVisible,
      projectTitle,
      projectDescription,
      projectBudget,
      selectedCategory,
      personInChargeName,
      personInChargePhone,
      personInChargeEmail,
      updateProject,
      categories
    }
  }
});

