
import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

interface UserProfile {
  name: string;
  phone: string;
  email: string;
  gender: string;
  dob: string;
  year_experience: number;
  wage_per_hour:number;
  cover_note:string;
  skills: Skill[];
  educations: Education[];
  experiences: Experience[];
}

interface Skill {
  ID: number;
  skill_name: string;
  skill_proficiency: number;
}

interface Experience {
  ID: number;
  title: string;
  company: string;
  from_period: string;
  to_period: string;
  description: string;
  currently_work_here: string | number; 
  status: string | number; 
}

interface Education {
  ID: number;
  education_level: string;
  year_of_education: number | null;
  venue_of_education: string;
  major: string;
  from_period: string;
  to_period: string;
  description: string;
  status: string | number; 
}

export default defineComponent({
  name: 'FreelancerView',
  props: {
    freelancer_id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const profile = ref<{ 
      user: UserProfile, 
      skills: Skill[], 
      educations: Education[], 
      experiences: Experience[] 
    }>({
      user: {} as UserProfile, 
      skills: [], 
      educations: [], 
      experiences: []
    });

    onMounted(async () => {
      const url = `${store.state.host_url}user/profile/${props.freelancer_id}`;
      console.log(url)
      try {
        const response = await axios.post(url);
        profile.value = {
          user: response.data.user,
          skills: response.data.skills,
          educations: response.data.education, // Modified to match the backend's structure
          experiences: response.data.experience // Modified to match the backend's structure
        };
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    });

    function getEducationLevel(level: string): string {
      const educationLevels: { [key: string]: string } = {
        'primary_school': t('message.primarySchool'),
        'middle_school': t('message.middleSchool'),
        'high_school': t('message.highSchool'),
        'vocational': t('message.vocational'),
        'diploma': t('message.diploma'),
        'associate': t('message.associateDegree'),
        'bachelor': t('message.bachelorsDegree'),
        'postgraduate': t('message.postgraduateDiploma'),
        'masters': t('message.mastersDegree'),
        'phd': t('message.doctorate'),
        'professional': t('message.professionalQualification')
      };
      
      return educationLevels[level] || level;
    }

    return {
      profile,
      getEducationLevel
    };
  }
});
