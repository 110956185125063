
import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios';
import DisplayCard from '../DisplayCard/DisplayCard.components.vue';
import CardDetails from '../DisplayCard/types';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'Latestproject',
  components: {
    DisplayCard,
  },
  setup() {
    const cardDatas = ref<CardDetails[]>([]);
    const store = useStore();
    const fetchLatestProjects = async () => {
      try {
        const apiUrl = store.state.host_url + 'latest-projects';
        const response = await axios.get(apiUrl); // Modify to the correct endpoint
        if (response.data && response.data.projects) {
          cardDatas.value = response.data.projects;
        }
      } catch (error) {
        console.error('Error fetching latest projects:', error);
      }
    };

    onMounted(fetchLatestProjects);

    return {
      cardDatas
    }
  },
});
