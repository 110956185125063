
import { defineComponent, onMounted, ref  } from 'vue'
import TncDisplay from'@/components/TncDisplay/TncDisplay.components.vue'
import {TncDisplayClasses} from '@/components/TncDisplay/export_classes'
import { useStore } from 'vuex';
import axios from 'axios';
import { useRouter } from 'vue-router'; 
import { AxiosError } from 'axios';
export default defineComponent({
    name: 'RegisterView',
    components: {
        TncDisplay,
    },
    setup () {
        let isTncToggle = ref<boolean>(false);
        let body:HTMLElement;
        let tncModal:HTMLElement;    
        let formData = ref({
            name: '',
            gender: 'M', 
            dob: '',
            email: '',
            phone: '',
            password: '',
            confirm_password: '',
            tnc: false
        });
        // toggle tnc modal
        const toggleTnc = ():void =>{            
            tncModal.classList.contains('active') ? isTncToggle.value = true : isTncToggle.value = false;

            if (!isTncToggle.value) {     
                tncModal.classList.add(TncDisplayClasses.active as string)
                body?.setAttribute('style', 'overflow: hidden');
            } else {
                tncModal.classList.remove('active');
                body?.removeAttribute('style');
            }            
        }        

        const updateTncStatus = ():void => {
            console.log();
                                    
        }
        const store = useStore();
        const router = useRouter();
        const isFormValid = (): boolean => {
            // Check if any string fields are empty
            if (
                !formData.value.name ||
                !formData.value.gender ||
                !formData.value.dob ||
                !formData.value.email ||
                !formData.value.phone ||
                !formData.value.password ||
                !formData.value.confirm_password
            ) {
                alert('All fields must be filled out.');
                return false;
            }

            // Check if passwords match
            if (formData.value.password !== formData.value.confirm_password) {
                alert('Password and confirm password must match.');
                return false;
            }

            // Check if terms and conditions are accepted
            if (!formData.value.tnc) {
                alert('You must agree to the terms and conditions before proceeding.');
                return false;
            }

            return true;
        };
        const registerUser = async (): Promise<void> => {
            console.log( formData.value)
            if (!isFormValid()) {
                return;  // Stop the function if the form is not valid
            }

            const apiUrl = store.state.host_url + "user/register";  // Concatenate host_url with endpoint
            try {
        
                const response = await axios.post(apiUrl, formData.value);
                console.log(response.data);

                if (response.data.message === 'User registered successfully!') {
                    router.push('/login');  // <-- This navigates to the login route
                }
            }  catch (error) {
                const axiosError = error as AxiosError;
                if (axiosError.response && axiosError.response.status === 409) {
                    alert('User with this email is already registered.');
                } else {
                    alert('An error occurred. Please try again.');
                }
                
            }
        };

        onMounted(()=>{
            body = document.querySelector('body')!;     
            tncModal = document.querySelector('#tnc-display')!;
            store.commit('HostUrl', 'https://backend.myfreelancer.com.my/api/');                   
        });

    
        return{
            toggleTnc,
            isTncToggle,
            updateTncStatus,
            formData,
            registerUser
        }
    },
    
});
