
import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';

interface User {
  ID: number;
  name: string;
  email: string;
  password: string;
  gender: string;
  dob: string;
  phone: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
  year_experience: number;
  wage_per_hour: number;
  cover_note: string;
}

interface Language {
  id: number;
  name: string;
  proficiency: string;
}

interface Skill {
    user_id: number;
    ID: number;
    name_eng: string;
    name_chi: string;
    name_malay: string;
}

interface Profile {
  user: User;
  languages: Language[];
  skills: SkillName[];
}

interface SkillName {
  ID: number;
  user_id: number;
  skill_name: string;
  skill_proficiency: string;
  created_by: number;
  created_at: string;
  updated_by: number;
  updated_at: string;
  status: number;
}

export default defineComponent({
  name: 'AllFreelancersView',
  setup() {
    const skills = ref<Skill[]>([]);
    const store = useStore();
    const BASE_URL = store.state.host_url;
    const profiles = ref<Profile[]>([]); // Note the type here
    const filters = ref({
      name: '',
      skill: 'all',
      hourlyRate: 'all'
    });

    onMounted(async () => {
      try {
        const skillsResponse = await axios.get(BASE_URL + "skills");
        skills.value = skillsResponse.data;
      } catch (error) {
        console.error("Error fetching skills:", error);
      }
      fetchFreelancers();
    });

    const fetchFreelancers = async () => {
      try {
        const response = await axios.get(BASE_URL + "freelancers", {
          params: filters.value
        });
        profiles.value = response.data.profiles;  // Accessing profiles from the response
      } catch (error) {
        console.error("Error fetching freelancers:", error);
      }
    };

    return {
      profiles,  // Updated this as well
      filters,
      skills,
      fetchFreelancers
    };
  }
});
