import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelRadio as _vModelRadio, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d225bd0c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "body-section",
  id: "register"
}
const _hoisted_2 = { class: "login-card" }
const _hoisted_3 = { class: "mb-4 text-center" }
const _hoisted_4 = { class: "form-container" }
const _hoisted_5 = { class: "login-form-container" }
const _hoisted_6 = { for: "text" }
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = { class: "row login-form-container gy-5 gy-lg-0" }
const _hoisted_9 = { class: "col-lg-6 px-0 px-lg-2" }
const _hoisted_10 = { class: "gender-checkbox" }
const _hoisted_11 = {
  for: "M",
  class: "p-3"
}
const _hoisted_12 = {
  for: "F",
  class: "p-3"
}
const _hoisted_13 = { class: "col-lg-6 px-0 px-lg-2" }
const _hoisted_14 = { for: "date_of_birth" }
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = { class: "login-form-container row gy-5 gy-lg-0" }
const _hoisted_17 = { class: "col-lg-6 px-0 px-lg-2" }
const _hoisted_18 = { for: "email" }
const _hoisted_19 = ["placeholder"]
const _hoisted_20 = { class: "col-lg-6 px-0 px-lg-2" }
const _hoisted_21 = { for: "phone" }
const _hoisted_22 = ["placeholder"]
const _hoisted_23 = { class: "login-form-container" }
const _hoisted_24 = { for: "password" }
const _hoisted_25 = ["placeholder"]
const _hoisted_26 = { class: "login-form-container" }
const _hoisted_27 = { for: "password" }
const _hoisted_28 = ["placeholder"]
const _hoisted_29 = { class: "login-form-container tnc-checkbox" }
const _hoisted_30 = {
  for: "tnc",
  class: "tnc-label"
}
const _hoisted_31 = { class: "login-form-container text-center" }
const _hoisted_32 = { class: "button-main login" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TncDisplay = _resolveComponent("TncDisplay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('message.register')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("form", {
          onSubmit: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.registerUser && _ctx.registerUser(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('message.Name')) + ":", 1),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              name: "name",
              id: "name",
              placeholder: _ctx.$t('message.Name'),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.name) = $event))
            }, null, 8, _hoisted_7), [
              [_vModelText, _ctx.formData.name]
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('message.Gender')) + ":", 1),
              _createElementVNode("div", _hoisted_10, [
                _withDirectives(_createElementVNode("input", {
                  type: "radio",
                  name: "gender",
                  id: "male",
                  checked: "",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.gender) = $event))
                }, null, 512), [
                  [_vModelRadio, _ctx.formData.gender]
                ]),
                _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('message.male')), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "radio",
                  name: "gender",
                  id: "female",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.gender) = $event))
                }, null, 512), [
                  [_vModelRadio, _ctx.formData.gender]
                ]),
                _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('message.female')), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('message.Date of Birth')) + ":", 1),
              _withDirectives(_createElementVNode("input", {
                type: "date",
                name: "dob",
                id: "date_of_birth",
                placeholder: _ctx.$t('message.Date of Birth'),
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.dob) = $event))
              }, null, 8, _hoisted_15), [
                [_vModelText, _ctx.formData.dob]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('message.Email')) + ":", 1),
              _withDirectives(_createElementVNode("input", {
                type: "email",
                name: "email",
                id: "email",
                placeholder: _ctx.$t('message.Email'),
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.email) = $event))
              }, null, 8, _hoisted_19), [
                [_vModelText, _ctx.formData.email]
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('message.phone')) + ":", 1),
              _withDirectives(_createElementVNode("input", {
                type: "tel",
                name: "phone",
                id: "phone",
                placeholder: _ctx.$t('message.phone'),
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.phone) = $event))
              }, null, 8, _hoisted_22), [
                [_vModelText, _ctx.formData.phone]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.$t('message.Password')) + ":", 1),
            _withDirectives(_createElementVNode("input", {
              type: "password",
              name: "password",
              id: "password",
              placeholder: _ctx.$t('message.Password'),
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.password) = $event))
            }, null, 8, _hoisted_25), [
              [_vModelText, _ctx.formData.password]
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t('message.Confirm Password')) + ":", 1),
            _withDirectives(_createElementVNode("input", {
              type: "password",
              name: "confirm_password",
              id: "confirm_password",
              placeholder: _ctx.$t('message.Confirm Password'),
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.confirm_password) = $event))
            }, null, 8, _hoisted_28), [
              [_vModelText, _ctx.formData.confirm_password]
            ])
          ]),
          _createElementVNode("div", _hoisted_29, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              name: "tnc",
              id: "tnc",
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.tnc) = $event))
            }, null, 512), [
              [_vModelCheckbox, _ctx.formData.tnc]
            ]),
            _createElementVNode("label", _hoisted_30, [
              _createElementVNode("a", {
                href: "#tnc",
                class: "tnc-link",
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.toggleTnc && _ctx.toggleTnc(...args)))
              }, _toDisplayString(_ctx.$t('message.termAndCondition')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("button", _hoisted_32, _toDisplayString(_ctx.$t('message.register')), 1)
          ])
        ], 32)
      ]),
      _createVNode(_component_TncDisplay)
    ])
  ]))
}