
import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
interface Skill {
    user_id: number;
    ID: number;
    skill_name: string;
    skill_proficiency: number;
    created_by: number;
    updated_by: number;
    status: number;
}

export default defineComponent({
    name: 'SkillsView',
    setup() {
        const store = useStore();
        const skill = ref({ skill_name: '' });
        const allSkills = ref<Array<{ ID: number; name_eng: string }>>([]);
        const skills = ref<Skill[]>([]);
        const changedSkills = ref<number[]>([]);
        const get_user_api = store.state.host_url + "get-user-skill?user_id="+ store.state.user_id;
        const store_user_api = store.state.host_url + "user-skill";
        const update_skill_api = store.state.host_url + "user-skill/";
        const suggestedSkills = ref<string[]>([]);
        const filteredSkills = ref<string[]>([]);
        const isAutocompleteVisible = ref(false);

        const trackChanges = (skillId: number) => {
            if (!changedSkills.value.includes(skillId)) {
                changedSkills.value.push(skillId);
            }
        };
        const fetchSkills = async () => {
            try {
                const response = await axios.get(get_user_api);
                skills.value = response.data.skills; // Assuming the endpoint returns an array of skills
            } catch (error) {
                alert('Error fetching skills.');
            }
        };

        const addSkill = async () => {
            if (!skill.value.skill_name.trim()) {
                alert('Please enter a skill name.');
                return;
            }
            try {
                const data = {
                    skill_name: skill.value.skill_name,
                    user_id: store.state.user_id,
                    skill_proficiency :1
                }
                console.log(data)
                const response =  await axios.post(store_user_api, data);
                if (response.data.message === 'Record created successfully') {
                    skills.value.push(response.data.data);
                    skill.value.skill_name = ''; // Reset skill name after successful addition
                    alert(response.data.message);
                } else {
                    alert('Error adding skill.');
                }
            } catch (error) {
                alert('Error adding skill.');
            }
        };

        const deleteSkill = async (skillId: number) => {
            try {
                const delete_user_api = store.state.host_url + "user-skill/" + skillId;
                const response = await axios.delete(delete_user_api);
                if (response.data.message === 'Record status changed to 0 successfully') {
                    const index = skills.value.findIndex(s => s.ID === skillId);
                    if (index > -1) {
                        skills.value.splice(index, 1);
                    }
                    alert(response.data.message);
                } else {
                    alert('Error deleting skill.');
                }
            } catch (error) {
                alert('Error deleting skill.');
            }
        };

       const saveSkills = async () => {
            for (const skillId of changedSkills.value) {
                const skillToUpdate = skills.value.find(s => s.ID === skillId);
                if (skillToUpdate) {
                    try {
                        console.log(update_skill_api + skillId);
                        console.log(skillToUpdate)
                        const response = await axios.post(update_skill_api + skillId, skillToUpdate);
                        if (response.data.message !== 'Record updated successfully') {
                            alert(`Error updating skill: ${skillToUpdate.skill_name}`);
                        }
                    } catch (error) {
                        alert(`Error updating skill: ${skillToUpdate.skill_name}`);
                    }
                }
            }
            alert('Skills updated successfully');
            changedSkills.value = []; // Reset after updating
        };

        onMounted(async () => {
            try {
                const skillsResponse = await axios.get(store.state.host_url + "skills");
                if (skillsResponse.data && Array.isArray(skillsResponse.data)) {
                    allSkills.value = skillsResponse.data;
                }
                fetchSkills();
            } catch (error) {
                console.error("Error fetching skills:", error);
            }
        });

        const selectSkill = (selectedSkill: string) => {
            skill.value.skill_name = selectedSkill;
            filteredSkills.value = [];
            isAutocompleteVisible.value = false;
        }

        const filterSkills = () => {
            if (!skill.value || !skill.value.skill_name) {
                filteredSkills.value = [];
                isAutocompleteVisible.value = false;
                return;
            }

            filteredSkills.value = allSkills.value
                .map(skillItem => skillItem.name_eng)
                .filter(name => name && name.toLowerCase().includes(skill.value.skill_name.toLowerCase()));

            isAutocompleteVisible.value = filteredSkills.value.length > 0;
        }


     
        // Function to set input value to selected suggestion
       
        return {
            skill,
            skills,
            addSkill,
            deleteSkill,
            saveSkills,
            trackChanges,
            filterSkills,
            suggestedSkills,
            isAutocompleteVisible,
            selectSkill,
            filteredSkills 
        };
    }
});
