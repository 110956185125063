
import { defineComponent, onMounted, ref, computed  } from 'vue'
import { useStore } from 'vuex';

export default defineComponent({
    name: 'TncDisplay',
    components: {},
    setup() {
        const isTncToggle = ref<boolean>(true); 
        let tncModal:HTMLElement;
        let container:HTMLElement;
        let body:HTMLElement;    
        const store = useStore();    
        const language = computed(() => store.state.language);
        onMounted(
            ()=>{
                tncModal = document.querySelector('#tnc-display')!;
                container = tncModal.querySelector('.container')!;
                body = document.querySelector('body')!;

                tncModal?.addEventListener('click', (e:MouseEvent)=>{
                    e.stopPropagation();
                    if (container.contains(e.target as Node)) return isTncToggle.value = true;
                    tncModal.classList.remove('active');
                    body.removeAttribute('style');
                    isTncToggle.value = false;                    
                })
            }
        )

        return{
            isTncToggle,
            language
        }
    }, 
});

