
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import axios from 'axios';  // Assuming you are using axios for HTTP requests

export default defineComponent({
  name: 'EditEducationView',
  
  setup() {
    const route = useRoute();
    const store = useStore();
    const education = ref({
      major: '',
      venue_of_education: '',
      from_period: '',
      to_period: '',
      description: '',
      education_level: ''
    });
    const apiUrl = store.state.host_url +  `user-education/${route.params.id}`;

    onMounted(async () => {
      try {
        const response = await axios.get(apiUrl);
        if (response.data) {
          education.value = response.data.education;
        } else {
          console.error('Error fetching education data:', response.data.message);
        }
      } catch (error) {
        console.error('Network error fetching education:', error);
      }
    });

    const updateEducation = async () => {
      try {
        const response = await axios.post(apiUrl, education.value);
        if (response.data.success) {
          alert('Education updated successfully');
        } else {
          alert('Error updating education: ' + response.data.message);
        }
      } catch (error) {
        alert('Network error. Please try again.');
      }
    };

    return {
      education,
      updateEducation
    };
  }
});
