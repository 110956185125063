import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7108463a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home body-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageHero = _resolveComponent("ImageHero")!
  const _component_UploadProject = _resolveComponent("UploadProject")!
  const _component_AboutUs = _resolveComponent("AboutUs")!
  const _component_LatestProject = _resolveComponent("LatestProject")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ImageHero),
    _createVNode(_component_UploadProject),
    _createVNode(_component_AboutUs),
    _createVNode(_component_LatestProject)
  ]))
}