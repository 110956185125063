
import { defineComponent, ref, computed, onMounted  } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { i18n } from "@/main";

export default defineComponent({
  name: 'Navbar',
  setup() {
    const isLanguageActive = ref<boolean>(false);
    const isMenuToggle = ref<boolean>(false);
    const store = useStore();
    const isLogin = computed(() => store.state.is_login);
    const router = useRouter();
    const dynamicComponent = ref('div');
    const forceUpdate = ref(0);
    const currentLang = ref<String>('en');
    const toggleLanguage = (e: MouseEvent): void => {
      e.stopPropagation();
      isLanguageActive.value = !isLanguageActive.value;
    };

    const toggleMenu = (e: MouseEvent): void => {
      e.stopPropagation();
      isMenuToggle.value = !isMenuToggle.value;
    };

    const closeMenu = (e: MouseEvent): void => {
      e.stopPropagation();
      isMenuToggle.value = false;
    };

    const logout = (e: MouseEvent): void => {
      e.preventDefault();
      store.commit('IsLogin', false);
      router.push('/login');
    };

    const changeLanguage = (lang: string): void => {
      store.commit('Language', lang);
      (i18n as any).global.locale = lang;
      window.location.reload();
    };

    onMounted(()=>{
      // check and update current language in language box
      currentLang.value = store.state.language;
    })
    
  
    return {
      isLanguageActive,
      toggleLanguage,
      isMenuToggle,
      toggleMenu,
      closeMenu,
      logout,
      isLogin,
      changeLanguage,
      dynamicComponent,
      currentLang
    };
  }
});
