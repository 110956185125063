import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ff8cb028"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "latest-project" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "text-center mb-5" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2" }
const _hoisted_6 = {
  key: 1,
  class: "card-container null"
}
const _hoisted_7 = { class: "card" }
const _hoisted_8 = { class: "button" }
const _hoisted_9 = { class: "card-container view-more" }
const _hoisted_10 = { href: "/projects" }
const _hoisted_11 = { class: "card" }
const _hoisted_12 = { class: "button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DisplayCard = _resolveComponent("DisplayCard")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('message.latestProject')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.latestProjectDescription', { email: 'myfreelancermy@gmail.com' })), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.cardDatas.length > 0)
          ? (_openBlock(), _createBlock(_component_DisplayCard, {
              key: 0,
              CardDatas: _ctx.cardDatas
            }, null, 8, ["CardDatas"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('message.noProjectTitle')), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.noProjectDescription')), 1)
                ])
              ])
            ])),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("a", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("h4", null, _toDisplayString(_ctx.$t('message.viewMore')), 1)
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}