
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'; 

export default defineComponent({
  name: 'UploadProject',
  components: {},
  props: {},
  setup() {
    const store = useStore(); 
    const router = useRouter(); 

    const redirectTo = () => {
      if (store.state.is_login) {
        router.push('/create-project');
      } else {

        router.push('/login');
      }
    }

    return {
      redirectTo // make the method available to the template
    }
  }
});

