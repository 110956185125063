
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HeroButton',
  props: {
    title: {
        required: true,
        type: String
    }
  },
});

