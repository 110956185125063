import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5302d208"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "upload-project-section align-items-center d-flex" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row w-100 align-items-center d-flex" }
const _hoisted_4 = { class: "col-lg-8 text-container my-2" }
const _hoisted_5 = { class: "col-lg-4 d-flex project-button my-2" }
const _hoisted_6 = { class: "button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h4", null, _toDisplayString(_ctx.$t('message.postYourProject')), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("a", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.redirectTo && _ctx.redirectTo(...args))),
            href: "javascript:void(0)",
            class: ""
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h5", null, _toDisplayString(_ctx.$t('message.uploadProject')), 1)
            ])
          ])
        ])
      ])
    ])
  ]))
}