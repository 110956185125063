import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cae2ee1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "body-section",
  id: "forget-password"
}
const _hoisted_2 = { class: "login-card" }
const _hoisted_3 = { class: "mb-4 text-center" }
const _hoisted_4 = { class: "form-container" }
const _hoisted_5 = { class: "login-form-container" }
const _hoisted_6 = { for: "oldPassword" }
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = { class: "login-form-container" }
const _hoisted_9 = { for: "newPassword" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = { class: "login-form-container" }
const _hoisted_12 = { for: "confirmPassword" }
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = { class: "login-form-container text-center" }
const _hoisted_15 = {
  class: "button-main login",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('message.change_password')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("form", {
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleChangePassword && _ctx.handleChangePassword(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('message.oldPassword')), 1),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.oldPassword) = $event)),
              type: "password",
              placeholder: _ctx.$t('message.oldPassword')
            }, null, 8, _hoisted_7), [
              [_vModelText, _ctx.oldPassword]
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('message.newPassword')), 1),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newPassword) = $event)),
              type: "password",
              placeholder: _ctx.$t('message.newPassword')
            }, null, 8, _hoisted_10), [
              [_vModelText, _ctx.newPassword]
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('message.confirmNewPassword')), 1),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.confirmPassword) = $event)),
              type: "password",
              placeholder: _ctx.$t('message.confirmNewPassword')
            }, null, 8, _hoisted_13), [
              [_vModelText, _ctx.confirmPassword]
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("button", _hoisted_15, _toDisplayString(_ctx.$t('message.change_password')), 1)
          ])
        ], 32)
      ])
    ])
  ]))
}