
import { defineComponent, ref } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';  // Assuming you're using Vuex for state management

interface Education {
    user_id: number;
    venue_of_education: string; 
    from_period: Date | null;
    to_period: Date | null;
    major: string;
    description: string;
    education_level: string;

}

export default defineComponent({
    name: 'CreateEducationView',
    setup() {
        const store = useStore();
        const education = ref<Education>({
            user_id: store.state.user_id,  // Assuming user_id is stored in Vuex
            venue_of_education: '',
            from_period: null,
            to_period: null,
            major: '',
            description: '',
            education_level: '',
        });
        const apiUrl = store.state.host_url + "user-education";
        const addEducation = async () => {
            try {
                console.log(education.value)
                const response = await axios.post(apiUrl, education.value);

                if (response.status === 201) {
                    alert("Education added successfully");
                    education.value = {
                        user_id: store.state.user_id,  
                        venue_of_education: '',
                        from_period: null,
                        to_period: null,
                        major: '',
                        description: '',
                        education_level: '',
                    };

                } else {
                    alert("Error adding education");
                }
            } catch (error) {
                alert((error as Error).message);
            }
        }

        return {
            education,
            addEducation
        };
    }
});
