

interface Project {
  ID: number;
  user_id: number;
  title: string;
  description: string;
  status: string;
  budget: string;
  pic_name: string;
  pic_phone: string;
  pic_email: string;
  created_at: string;
  created_by: number | null;
  updated_at: string;
  updated_by: number | null;
  category_id: number;
  bids_count: number;
  project_skills: ProjectSkill[];
  category: Category;
}


interface ProjectSkill {
  ID: number;
  project_id: number;
  skill_id: number | null;
  skill_name: string;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;
}

interface Category {
  ID: number;
  name_eng: string;
  name_chi: string;
  name_malay: string;
}
import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'AllProjectsView',
  setup() {
    const store = useStore();
    const BASE_URL = store.state.host_url;
    const projects = ref<Project[]>([]);
    const skills = ref<Array<{ ID: number; name_eng: string }>>([]);
    const categories = ref<Category[]>([]);
    const filters = ref({
      category: 'all',
      skill: 'all'
    });

    onMounted(async () => {
      try {
        const skillsResponse = await axios.get(BASE_URL + "skills");
        skills.value = skillsResponse.data;

        const categoryResponse = await axios.get(BASE_URL + "categories");
        categories.value = categoryResponse.data;

        fetchProjects();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });

    const fetchProjects = async () => {
      try {
        const response = await axios.get(BASE_URL + "projects", {
          params: filters.value
        });
        projects.value = response.data.data;
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    return {
      projects,
      skills,
      categories,
      filters,
      fetchProjects
    };
  }
});

