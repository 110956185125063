import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b1316cf8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "body-section",
  id: "login"
}
const _hoisted_2 = { class: "login-card" }
const _hoisted_3 = { class: "mb-4 text-center" }
const _hoisted_4 = { class: "form-container" }
const _hoisted_5 = { class: "login-form-container" }
const _hoisted_6 = { for: "email" }
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = { class: "login-form-container" }
const _hoisted_9 = { for: "password" }
const _hoisted_10 = { class: "password-input" }
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = { class: "remember-me" }
const _hoisted_13 = { class: "login-form-container text-center" }
const _hoisted_14 = {
  class: "button-main login",
  type: "submit"
}
const _hoisted_15 = { class: "login-form-footer" }
const _hoisted_16 = { class: "link" }
const _hoisted_17 = { href: "/forget-password" }
const _hoisted_18 = { class: "link" }
const _hoisted_19 = { href: "/register" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('message.login')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("form", {
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleLogin && _ctx.handleLogin(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('message.Email')) + ":", 1),
            _withDirectives(_createElementVNode("input", {
              type: "email",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
              name: "email",
              id: "email",
              placeholder: _ctx.$t('message.Email')
            }, null, 8, _hoisted_7), [
              [_vModelText, _ctx.email]
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('message.Password')) + ":", 1),
            _createElementVNode("div", _hoisted_10, [
              _withDirectives(_createElementVNode("input", {
                type: "password",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                name: "password",
                id: "password",
                placeholder: _ctx.$t('message.Password')
              }, null, 8, _hoisted_11), [
                [_vModelText, _ctx.password]
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("label", null, [
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.remember) = $event)),
                  name: "remember",
                  id: "remember"
                }, null, 512), [
                  [_vModelCheckbox, _ctx.remember]
                ]),
                _createTextVNode(_toDisplayString(_ctx.$t('message.rememberMe')), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("button", _hoisted_14, _toDisplayString(_ctx.$t('message.login')), 1)
          ])
        ], 32),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("a", _hoisted_17, _toDisplayString(_ctx.$t('message.Forget Password?')), 1)
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("a", _hoisted_19, _toDisplayString(_ctx.$t('message.Create an Account')), 1)
          ])
        ])
      ])
    ])
  ]))
}