
import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'EditOthersView',
    setup() {
        const store = useStore();

        // Reactive references
        const yearExperience = ref<number | null>(null);
        const expectedPayment = ref<number | null>(null);
        const coverNotes = ref<string>('');

        // Fetch user's current details
        const fetchUserDetails = async () => {
            const url = `${store.state.host_url}user/profile/${store.state.user_id}`;
            try {
                const response = await axios.post(url);
                yearExperience.value = response.data.user.year_experience ?? '';
                expectedPayment.value = response.data.user.wage_per_hour ?? '';
                coverNotes.value = response.data.user.cover_note ?? "";
               
            } catch (error) {
                alert("Error fetching user details. Please try again.");
            }
        };

        onMounted(fetchUserDetails);

        const saveChanges = async () => {
            const apiUrl = store.state.host_url + 'user/update-others';
            try {
                const response = await axios.post(apiUrl, {
                    year_experience: yearExperience.value,
                    wage_per_hour: expectedPayment.value,
                    cover_note: coverNotes.value,
                    user_id: store.state.user_id
                });

                if (response.data.success) {
                    alert("Changes saved successfully!");
                } else {
                    alert(response.data.message);
                }
            } catch (error) {
                alert("Error updating information. Please try again.");
            }
        };

        return {
            yearExperience,
            expectedPayment,
            coverNotes,
            saveChanges
        };
    }
});
