import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26c7c584"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hero-section" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "title-container" }
const _hoisted_4 = { class: "mb-2" }
const _hoisted_5 = { class: "mb-2" }
const _hoisted_6 = { class: "hero-button-container" }
const _hoisted_7 = { href: "/freelancers" }
const _hoisted_8 = { href: "/projects" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeroButton = _resolveComponent("HeroButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('message.MyFreeLancer')), 1),
        _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.$t('message.MyFreeLancer is a dynamic platform connecting talented freelancers with businesses and individuals seeking specialized skills')), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("a", _hoisted_7, [
            _createVNode(_component_HeroButton, {
              title: _ctx.$t('message.GET A FREELANCER')
            }, null, 8, ["title"])
          ]),
          _createElementVNode("a", _hoisted_8, [
            _createVNode(_component_HeroButton, {
              title: _ctx.$t('message.START TO EARN MONEY')
            }, null, 8, ["title"])
          ])
        ])
      ])
    ])
  ]))
}