
import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios'; // Ensure you have axios installed and imported
import { useStore } from 'vuex';
import Swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';

interface ProjectSkill {
  ID: number;
  project_id: number;
  skill_id: number | null;
  skill_name: string;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;
}

interface Category {
  ID: number;
  name_eng: string;
  name_chi: string;
  name_malay: string;
}

interface Project {
  ID: number;
  user_id: number;
  title: string;
  description: string;
  status: string;
  budget: string; // It's a string here because the provided data has it in quotes. If it's actually a number, change to `number`.
  pic_name: string;
  pic_phone: string;
  pic_email: string;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;
  category_id: number;
  project_skills: ProjectSkill[];
  category: Category;
}

export default defineComponent({
  name: 'MyProject',
  setup() {
    const projects = ref<Project[]>([]);
    const closedProjects = ref<Project[]>([]);
    const store = useStore();
    const apiUrl = store.state.host_url + "my_project";
    const { t } = useI18n();
    const fetchProjects = async () => {
      try {
        const response = await axios.post(apiUrl, {
          user_id: store.state.user_id
        });
        
        // Assuming your API returns a list of projects
        projects.value = response.data;
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    
    const fetchClosedProjects = async () => {

      try {
        const response = await axios.post(store.state.host_url + "my_closed_projects", {
          user_id: store.state.user_id
        });

        closedProjects.value = response.data;

      } catch (error) {
        console.error("Error fetching closed projects:", error);
      }
    };

    const closeProject = async (projectId: number) => {
      try {
        const result = await Swal.fire({
          title: t('message.closeProject'),
          text: t('message.wontRevert'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: t('message.yesClose'),
          cancelButtonText: t('message.noCancel')
        });
        
        if (result.isConfirmed) {
          await axios.post(`${store.state.host_url}project/${projectId}/close`);
          Swal.fire('Closed!', t('message.project_closed'), 'success');
          fetchProjects(); 
        }
      } catch (error) {
        Swal.fire('Error!', t('message.closeProjectError'), 'error');
      }
    };

    const deleteProject = async (projectId: number) => {
      try {
        const result = await Swal.fire({
          title: t('message.deleteProject'),
          text: t('message.wontRevert'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: t('message.yesDelete'),
          cancelButtonText: t('message.noCancel')
        });
        
        if (result.isConfirmed) {
          await axios.post(`${store.state.host_url}project/${projectId}/delete`);
          Swal.fire('Deleted!', t('message.project_deleted'), 'success');
          fetchProjects(); 
        }
      } catch (error) {
        Swal.fire('Error!', t('message.deleteProjectError'), 'error');
      }
    };

    onMounted(() => {
      fetchProjects();
      fetchClosedProjects();  
    });

    return {
      projects,
      closeProject,
      closedProjects, 
      deleteProject
    }
  }
});
