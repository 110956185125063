
import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'; 
interface ProjectSkill {
  ID: number;
  project_id: number;
  skill_id: number | null;
  skill_name: string;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;
}

interface Project {
  ID: number;
  user_id: number;
  title: string;
  description: string;
  status: string;
  budget: string;
  pic_name: string;
  pic_phone: string;
  pic_email: string;
  created_at: string;
  created_by: number;
  updated_at: string;
  updated_by: number;
  category_id: number;
  project_skills: ProjectSkill[];
}
interface User {
    ID: number;
    name: string;
}

interface UserProfile{
  name: string;
  phone: string;
  email: string;
  gender: string;
  dob: string;
  year_experience: number;
  wage_per_hour:number;
  cover_note:string;
}

interface Bid {
    ID: number;
    user_id: number;
    project_id: number;
    amount: string;  
    notes: string | null;
    created_at: string;
    created_by: number;
    updated_at: string;
    updated_by: number;
    status: number;
    user: User;
}

export default defineComponent({
  name: 'ProjectView',
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  setup(props) {
    const project = ref<Project | null>(null);
    const user_profile = ref<UserProfile | null>(null);
    const store = useStore();
    const storeUserId = store.state.user_id; // Assuming you have user_id in your Vuex store
    const router = useRouter();
    const bidAmount = ref<number | null>(null);
    const userBid = ref<Bid | null>(null);
    const bidsForProject = ref<Bid[]>([]); 
    const fetchBidsForProject = async () => {
            try {
                const response = await axios.get(`${store.state.host_url}projects/${props.id}/bids`);
                bidsForProject.value = response.data.bids;
            } catch (error) {
                console.error("Error fetching bids for project:", error);
            }
        };

    const acceptBid = async (bidId: number) => {
        try {
            await axios.post(`${store.state.host_url}bid/accept`, {
                bid_id: bidId,
                user_id: store.state.user_id
            });
            fetchBidsForProject();  // refresh the bids list
            alert("Bid accepted successfully!");
        } catch (error) {
            console.error("Error accepting bid:", error);
        }
    };

    const rejectBid = async (bidId: number) => {
        try {
            await axios.post(`${store.state.host_url}bid/reject`, {
                bid_id: bidId,
                user_id: store.state.user_id
            });
            fetchBidsForProject();  // refresh the bids list
            alert("Bid rejected successfully!");
        } catch (error) {
            console.error("Error rejecting bid:", error);
        }
    };
    const createOrUpdateBid = async () => {
      try {
        console.log(user_profile.value)
        if(user_profile.value?.wage_per_hour == null || user_profile.value?.year_experience == null ){
          alert("Please fill up your year of experience and wage per hour in the accout profile");
          router.push('/my-account'); 
          return;
        }
        const response = await axios.post(`${store.state.host_url}bid`, {
          user_id: store.state.user_id,
          project_id: props.id,
          amount: bidAmount.value
        });

        fetchUserBid();
      } catch (error) {
        console.error("Error creating or updating bid:", error);
      }
    };

    const cancelBid = async () => {
      try {
        await axios.post(`${store.state.host_url}bid/cancel`, {
          user_id: store.state.user_id,
          project_id: props.id
        });
        userBid.value = null;
      } catch (error) {
        console.error("Error cancelling bid:", error);
      }
    };

    const fetchUserBid = async () => {
      try {
        const response = await axios.get(`${store.state.host_url}projects/${props.id}/bids/user/${store.state.user_id}`);
        userBid.value = response.data.bid || null;
        user_profile.value = response.data.user || null;
        console.log(response.data)
      } catch (error) {
        console.error("Error fetching user bid:", error);
      }
    };

    onMounted(async () => {
      try {
        const response = await axios.get(`${store.state.host_url}project/${props.id}`);
        project.value = response.data.project;
      } catch (error) {
        console.error("Error fetching project:", error);
      }
      if (project.value?.user_id !== store.state.user_id) {
        fetchUserBid();
        bidAmount.value = Number(project.value?.budget)
      }
      else{
        fetchBidsForProject();
      }
    });

    return {
      project,
      storeUserId,
      bidAmount,
      userBid,
      createOrUpdateBid,
      cancelBid,
      bidsForProject,
      acceptBid,
      rejectBid
    };
  }
});

